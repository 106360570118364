import ApiService from '@/core/services/api.service';

export const LIST_TRAINEE = 'listTrainee';
export const LIST_TRAINEES = 'listTrainees';
export const LIST_CUSTOMER_TRAINEE = 'listCustomerTrainee';
export const LIST_CUSTOMER_TRAINEE_BY_ROLE = 'listCustomerTraineeByRole';
export const SET_TRAINEE = 'setTrainee';
export const SET_TRAINEES = 'setTrainees';
export const CREATE_TRAINEE = 'createTrainee';
export const SET_CUSTOMER_TRAINEE = 'setCustomerTrainee';
export const SET_ERROR = 'setError';

const state = {
    errors: null,
    trainees: {},
    trainee: {},
    customer_trainee: {}
};

const getters = {
    getTrainee(state) {
        return state.trainee;
    },
    getTrainees(state) {
        return state.trainees;
    },
    getCustomerTrainee(state) {
        return state.customer_trainee;
    }
};

const actions = {
    [LIST_TRAINEE](context, data) {
        return new Promise((resolve) => {
            ApiService.get(
                process.env.VUE_APP_API_URL,
                'users?page=' + data.page + `${data.filter ? '&filter[lastname]=' + data.filter : ''}`
            ).then((result) => {
                context.commit(SET_TRAINEE, result.data);
                resolve(result);
            });
        });
    },
    [LIST_TRAINEES](context, data) {
        return new Promise((resolve) => {
            ApiService.query('users', data).then((result) => {
                context.commit(SET_TRAINEES, result.data);
                resolve(result);
            });
        });
    },
    /*
    // [REVUE DE CODE] modifications organisation code
    [LIST_CUSTOMER_TRAINEE](context, data) {
        return new Promise(resolve => {
            ApiService.query('users' + data.customer_id + '?page=' + data.page + `${data.filter ? '&filter[lastname]=' + data.filter : ''}`
            ).then(result => {
                context.commit(SET_CUSTOMER_TRAINEE, result.data);
                resolve(result);
            });
        });
    }, */
    [LIST_CUSTOMER_TRAINEE](context, data) {
        return new Promise((resolve) => {
            ApiService.query('users', data).then((result) => {
                context.commit(SET_CUSTOMER_TRAINEE, result.data);
                resolve(result);
            });
        });
    },
    [CREATE_TRAINEE](context, data) {
        return new Promise((resolve, reject) => {
            ApiService.post('users/create', data)
                .then(({ data }) => {
                    context.commit(SET_TRAINEE, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.error);
                    reject(response);
                });
        });
    },
    [LIST_CUSTOMER_TRAINEE_BY_ROLE](context, data) {
        return new Promise((resolve) => {
            ApiService.query('academy/users/' + data.customer_id + '/' + data.role_id).then((result) => {
                context.commit(SET_CUSTOMER_TRAINEE, result.data);
                resolve(result);
            });
        });
    }
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [SET_TRAINEE](state, trainees) {
        state.trainee = trainees;
    },
    [SET_TRAINEES](state, trainees) {
        state.trainees = trainees;
    },
    [SET_CUSTOMER_TRAINEE](state, customer_trainee) {
        state.customer_trainee = customer_trainee;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
