import ApiService from '@/core/services/api.service';

export const LIST_QUEUE = 'listQueue';
export const LIST_ALL_QUEUE = 'listAllQueue';
export const LIST_USER_IN_QUEUE = 'listUserInQueue';
export const SET_QUEUE = 'setQueue';
export const SET_ALL_QUEUE = 'setAllQueue';
export const SET_USER_IN_QUEUE = 'setUserInQueue';
export const SET_ERROR = 'setError';

const state = {
    errors: null,
    clientClasseQueue: [],
    oneUserInQueue: [],
    allQueue: []
};

const getters = {
    getQueue(state) {
        return state.clientClasseQueue;
    },
    getOneUserInQueue(state) {
        return state.oneUserInQueue;
    },
    getAllQueue(state) {
        return state.allQueue;
    }
};

const actions = {
    [LIST_QUEUE](context, data) {
        return new Promise((resolve) => {
            ApiService.query(`academy/clientclasses/${data.classCustomerId}/queue`, data).then((result) => {
                context.commit(SET_QUEUE, result.data);
                resolve(result);
            });
        });
    },
    [LIST_ALL_QUEUE](context) {
        return new Promise((resolve) => {
            ApiService.query(`academy/clientclasses/queue`).then((result) => {
                context.commit(SET_ALL_QUEUE, result.data);
                resolve(result);
            });
        });
    },
    [LIST_USER_IN_QUEUE](context, data) {
        return new Promise((resolve) => {
            ApiService.query(`academy/clientclasses/${data.classCustomerId}/queue/${data.userId}`, data).then((result) => {
                context.commit(SET_USER_IN_QUEUE, result.data);
                resolve(result);
            });
        });
    }
};

const mutations = {
    [SET_QUEUE](state, queue) {
        state.clientClasseQueue = queue;
    },
    [SET_ALL_QUEUE](state, listQueue) {
        state.allQueue = listQueue;
    },
    [SET_USER_IN_QUEUE](state, oneUserInQueue) {
        state.oneUserInQueue = oneUserInQueue;
    },
    [SET_ERROR](state, error) {
        state.errors = error;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
