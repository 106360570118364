import ApiService from '@/core/services/api.service';

export const LIST_CLASSE = 'listClasse';
export const LIST_ALL_CLASSES = 'listAllClasses';
export const LIST_ONLINE_CLASSES = 'listOnlineClasses';
export const LIST_CATALOG_CLASSES = 'listCatalogClasses';
export const SET_CLASSE = 'setClasse';
export const SET_ALL_CLASSES = 'setAllClasses';
export const SET_ONLINE_CLASSES = 'setOnlineClasses';
export const SET_CATALOG_CLASSES = 'setCatalogClasses';
export const SET_ERROR = 'setError';

const state = {
    errors: null,
    classe: {},
    allClasses: [],
    onlineClasses: [],
    catalogClasses: []
};

const getters = {
    getClasse(state) {
        return state.classe;
    },
    getAllClasses(state) {
        return state.allClasses;
    },
    getOnlineClasses(state) {
        return state.onlineClasses;
    },
    getCatalogClasses(state) {
        return state.catalogClasses;
    }
};

const actions = {
    [LIST_CLASSE](context, data) {
        return new Promise((resolve) => {
            ApiService.get(process.env.VUE_APP_API_URL, 'academy/classes/' + data).then((result) => {
                context.commit(SET_CLASSE, result.data);
                resolve(result);
            });
        });
    },
    [LIST_ALL_CLASSES](context) {
        return new Promise((resolve) => {
            ApiService.get(process.env.VUE_APP_API_URL, 'academy/classes').then((result) => {
                context.commit(SET_ALL_CLASSES, result.data);
                resolve(result);
            });
        });
    },
    [LIST_ONLINE_CLASSES](context) {
        return new Promise((resolve) => {
            ApiService.get(process.env.VUE_APP_API_URL, 'academy/classes/online').then((result) => {
                context.commit(SET_ONLINE_CLASSES, result.data);
                resolve(result);
            });
        });
    },
    [LIST_CATALOG_CLASSES](context, data) {
        return new Promise((resolve) => {
            ApiService.get(process.env.VUE_APP_API_URL, 'academy/classes/catalog/' + data.user_id).then((result) => {
                context.commit(SET_CATALOG_CLASSES, result.data);
                resolve(result);
            });
        });
    }
};

const mutations = {
    [SET_CLASSE](state, classe) {
        state.classe = classe;
    },
    [SET_ALL_CLASSES](state, data) {
        state.allClasses = data;
    },
    [SET_ONLINE_CLASSES](state, data) {
        state.onlineClasses = data;
    },
    [SET_CATALOG_CLASSES](state, data) {
        state.catalogClasses = data;
    },
    [SET_ERROR](state, error) {
        state.errors = error;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
