import ApiService from '@/core/services/api.service';

export const LIST_CLIENT = 'listClient';
export const LIST_ALL_CLIENT = 'listAllClient';
export const LIST_INFO_CLIENT = 'listInfoClient';
export const LIST_CLIENT_CLASSES = 'listClientClasses';
export const LIST_CLASSES = 'listClasses';
export const LIST_CUSTOMER_ID = 'listCustomerId';
export const LIST_CLIENT_GROUP = 'listCustomerGroup';
export const SET_CLIENT = 'setClient';
export const SET_CLIENTS = 'setClients';
export const SET_INFO_CLIENT = 'setInfoClient';
export const SET_CUSTOMER_ID = 'setCustomerId';
export const SET_CLASSES = 'setClasses';
export const SET_CLIENT_CLASSES = 'setClientClasses';
export const SET_ERROR = 'setError';

const state = {
    errors: null,
    client: {},
    info_client: {},
    classes: [],
    clientClasses: []
};

const getters = {
    getClient(state) {
        return state.client;
    },
    getClients(state) {
        return state.clients;
    },
    getClientClasses(state) {
        return state.clientClasses;
    },
    getClientHaveClasses(state) {
        return state.classes;
    },
    getInfoClient(state) {
        return state.info_client;
    },
    getCustomersByIdOfUser(state) {
        return state.customer;
    }
};

const actions = {
    [LIST_CLIENT](context, data) {
        return new Promise((resolve) => {
            ApiService.get(
                process.env.VUE_APP_API_URL,
                'customers?perpage=15&page=' + data.page + `${data.filter ? '&filter[code]=' + data.filter : ''}`
            ).then((result) => {
                context.commit(SET_CLIENT, result.data);
                resolve(result);
            });
        });
    },
    [LIST_ALL_CLIENT](context, data) {
        return new Promise((resolve) => {
            ApiService.query('customers', data).then((result) => {
                context.commit(SET_CLIENTS, result.data);
                resolve(result);
            });
        });
    },
    [LIST_CLIENT_CLASSES](context, params) {
        return new Promise((resolve) => {
            ApiService.query('academy/clientclasses', {
                params
            }).then((result) => {
                context.commit(SET_CLIENT_CLASSES, result.data);
                resolve(result);
            });
        });
    },
    [LIST_CLASSES](context, data) {
        return new Promise((resolve) => {
            ApiService.query('academy/clientclasses/classes/' + data.id, { params: data.params }).then((result) => {
                context.commit(SET_CLASSES, result.data);
                resolve(result);
            });
        });
    },
    [LIST_CUSTOMER_ID](context, customer_id) {
        return new Promise((resolve) => {
            ApiService.get(process.env.VUE_APP_API_URL, 'customers/affiliate/' + customer_id).then((result) => {
                context.commit(SET_CUSTOMER_ID, result.data);
                resolve(result);
            });
        });
    },
    [LIST_INFO_CLIENT](context, customer_id) {
        return new Promise((resolve) => {
            ApiService.get(process.env.VUE_APP_API_URL, 'customers/' + customer_id).then((result) => {
                context.commit(SET_INFO_CLIENT, result.data);
                resolve(result);
            });
        });
    },
    [LIST_CLIENT_GROUP](context, data) {
        return new Promise((resolve) => {
            ApiService.query('customers/' + data.customer_id + '/hierarchy', data).then((result) => {
                context.commit(SET_CLIENTS, result.data);
                resolve(result);
            });
        });
    }
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [SET_CLIENT](state, clients) {
        state.client = clients;
    },
    [SET_CLIENTS](state, clients) {
        state.clients = clients;
    },
    [SET_CLIENT_CLASSES](state, clients) {
        state.clientClasses = clients;
    },
    [SET_CLASSES](state, classes) {
        state.classes = classes;
    },
    [SET_CUSTOMER_ID](state, customer) {
        state.customer = customer;
    },
    [SET_INFO_CLIENT](state, info_client) {
        state.info_client = info_client;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
