import store from '@/core/services/store/index';

export default function authenticated({next}) {
    // Si le role n'est ni admin ni Superviseur et qu'il n'a pas le droit tuteur, on redirige vers la page d'accueil
    if (!store || !store.getters || !store.getters.currentUser) {
        return next({name: 'dashboard'});
    }

    if (store.getters.currentUser.role !== 'admin' && store.getters.currentUser.role !== 'Superviseur' && !checkRights([1])) {
        return next({name: 'dashboard'});
    }
    return next();
}

function checkRights(rightIds) {
    let validation = false;
    if (store.getters.currentUser && store.getters.currentUser.rights && store.getters.currentUser.rights.academy) {
        store.getters.currentUser.rights.academy.forEach(right => {
            if (rightIds.includes(right.right_id)) {
                validation = true;
            }
        });
        return validation;
    }
}