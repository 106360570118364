import ApiService from '@/core/services/api.service';

export const SET_ERROR = 'setError';
export const SET_SECONDARY_SITE = 'setSecondarySite';
export const LIST_SECONDARY_SITE = 'listSecondarySite';

const state = {
    errors: null,
    secondary_site: {}
};

const getters = {
    getSecondarySite(state) {
        return state.secondary_site;
    }
};

const actions = {
    [LIST_SECONDARY_SITE](context, customer_id) {
        return new Promise((resolve) => {
            ApiService.query('secondary_site/customer/' + customer_id).then((result) => {
                context.commit(SET_SECONDARY_SITE, result.data);
                resolve(result);
            });
        });
    }
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [SET_SECONDARY_SITE](state, secondary_site) {
        state.secondary_site = secondary_site;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
