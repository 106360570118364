import ApiService from '@/core/services/api.service';

export const GENERATE_URL = 'generateUrl';
export const SET_ERROR = 'setError';
export const GET_DEMO_URL = 'getDemoUrl';
export const SET_DEMO_URL = 'setDemoUrl';

const state = {
    errors: null,
    url: '',
    demo_url: {}
};

const getters = {
    getUrl(state) {
        return state.url;
    },
    getDemoUrl(state) {
        return state.demo_url;
    }
};

const actions = {
    [GENERATE_URL](context, data) {
        return new Promise((resolve, reject) => {
            ApiService.post('academy/scorm/generate', data)
                .then((result) => {
                    context.commit(GENERATE_URL, result.data);
                    resolve(result);
                })
                .catch((error) => {
                    context.commit(SET_ERROR, error);
                    reject(error);
                });
        });
    },
    [GET_DEMO_URL](context, scormId) {
        return new Promise((resolve, reject) => {
            ApiService.query('academy/scorm/demo/' + scormId)
                .then((result) => {
                    context.commit(SET_DEMO_URL, result.data);
                    resolve(result);
                })
                .catch((error) => {
                    context.commit(SET_ERROR, error);
                    reject(error);
                });
        });
    }
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [SET_DEMO_URL](state, demo_url) {
        state.demo_url = demo_url;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};