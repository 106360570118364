import ApiService from '@/core/services/api.service';

export const LIST_CLIENTS_CLASSES = 'listClientsClasses';
export const LIST_ONE_CLIENT_CLASSE = 'listOneClientClasse';
export const SET_CLIENTS_CLASSES = 'setClientsClasses';
export const SET_ONE_CLIENT_CLASSE = 'listOneClientClasse';

const state = {
    errors: null,
    clientsClasses: {},
    clientClasse: {}
};

const getters = {
    getClientsClasses(state) {
        return state.clientsClasses;
    },
    getOneClientClasse(state) {
        return state.clientClasse;
    }
};

const actions = {
    [LIST_CLIENTS_CLASSES](context, data) {
        return new Promise((resolve, reject) => {
            ApiService.query('academy/clientclasses', data)
                .then((result) => {
                    context.commit(SET_CLIENTS_CLASSES, result.data);
                    resolve(result);
                })
                .catch((error) => reject(error));
        });
    },
    [LIST_ONE_CLIENT_CLASSE](context, data) {
        return new Promise((resolve, reject) => {
            ApiService.query(`academy/clientclasses/${data}`)
                .then((result) => {
                    context.commit(SET_ONE_CLIENT_CLASSE, result.data);
                    resolve(result);
                })
                .catch((error) => reject(error));
        });
    }
};

const mutations = {
    [SET_CLIENTS_CLASSES](state, clientsClasses) {
        state.clientsClasses = clientsClasses;
    },
    [SET_ONE_CLIENT_CLASSE](state, clientClasse) {
        state.clientClasse = clientClasse;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
