import ApiService from '@/core/services/api.service';

export const LIST_USERS = 'listUsers';
export const LIST_USERS_MANAGE = 'listUsersManage';
export const LIST_USER_CLASSES = 'UserClasses';
export const USER_HAVE_CLASSE = 'UserHaveClasse';
export const CLASSE_USERS_IMPORT = 'classeUsersImport';
export const OPEN_LICENCES_TEST = 'openLicencesTest';
export const GET_CLASSEUSER = 'getClasseUser';
export const GET_CLASSEUSERS = 'getClasseUsers';
export const GET_TEST_PER_TOTAL = 'getTestPerTotal';
export const GET_LICENCES_PER_COMMERCIAL = 'getLicencesPerCommercial';
export const SET_USERS = 'setUsers';
export const SET_USERS_MANAGE = 'setUsersManage';
export const SET_USER_HAVE_CLASSE = 'setUserHaveClasse';
export const SET_USER_CLASSES = 'setUserClasses';
export const SET_CLASSEUSER = 'SetClasseUser';
export const SET_ERROR = 'setError';

const state = {
    errors: null,
    users: {},
    usersManage: {},
    user_have_classe: null,
    user_classes: [],
    classeUser: {}
};

const getters = {
    getUsers(state) {
        return state.users;
    },
    getUsersManage(state) {
        return state.usersManage;
    },
    getUserHaveClasse(state) {
        return state.user_have_classe;
    },
    getUserClasses(state) {
        return state.user_classes;
    },
    getClasseUser(state) {
        return state.classeUser;
    },
    getByCommercialId(state) {
        return state.classeUser;
    }
};

const actions = {
    [LIST_USERS](context, data) {
        return new Promise((resolve) => {
            ApiService.get(process.env.VUE_APP_API_URL, 'academy/classeUsers/' + data.classe.id + '/' + data.customer_id).then((result) => {
                context.commit(SET_USERS, result.data);
                resolve(result);
            });
        });
    },
    [LIST_USERS_MANAGE](context, data) {
        return new Promise((resolve, reject) => {
            ApiService.get(process.env.VUE_APP_API_URL, 'academy/classeUsers/' + data.customerClasse.classe.id + '/' + data.customerId)
                .then((result) => {
                    context.commit(SET_USERS_MANAGE, result.data);
                    resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    [USER_HAVE_CLASSE](context, data) {
        return new Promise((resolve) => {
            ApiService.get(process.env.VUE_APP_API_URL, 'academy/classeUsers/classe/' + data.classe_id + '/user/' + data.user_id).then(
                (result) => {
                    context.commit(SET_USERS, result.data);
                    resolve(result);
                }
            );
        });
    },
    [CLASSE_USERS_IMPORT](context, data) {
        return new Promise((resolve, reject) => {
            ApiService.post('academy/classeUsers/import', data)
                .then((result) => {
                    resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    [LIST_USER_CLASSES](context, data) {
        return new Promise((resolve) => {
            ApiService.get(process.env.VUE_APP_API_URL, 'academy/classeUsers/user/' + data.user_id).then((result) => {
                context.commit(SET_USER_CLASSES, result.data);
                resolve(result);
            });
        });
    },
    [GET_CLASSEUSER](context, data) {
        return new Promise((resolve, reject) => {
            ApiService.get(process.env.VUE_APP_API_URL, 'academy/classeUsers/' + data.classeUser_id)
                .then((result) => {
                    context.commit(SET_CLASSEUSER, result.data);
                    resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    [GET_CLASSEUSERS](context, data) {
        return new Promise((resolve, reject) => {
            ApiService.get(process.env.VUE_APP_API_URL, 'academy/classeUsers/classe/commercial/' + data.commercial_id)
                .then((result) => {
                    context.commit(SET_CLASSEUSER, result.data);
                    resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    [OPEN_LICENCES_TEST](context, data) {
        return new Promise((resolve, reject) => {
            ApiService.post('academy/classeUsers/licencetest', data)
                .then((result) => {
                    resolve(result);
                })
                .catch((error) => {
                    context.commit(SET_ERROR, error);
                    reject(error);
                });
        });
    },
    [GET_TEST_PER_TOTAL](context) {
        return new Promise((resolve, reject) => {
            ApiService.query('academy/classeUsers/stats/testPerTotal')
                .then((result) => {
                    resolve(result);
                })
                .catch((error) => {
                    context.commit(SET_ERROR, error);
                    reject(error);
                });
        });
    },
    [GET_LICENCES_PER_COMMERCIAL](context) {
        return new Promise((resolve, reject) => {
            ApiService.query('academy/classeUsers/stats/getCommercialsNames')
                .then((result) => {
                    resolve(result);
                })
                .catch((error) => {
                    context.commit(SET_ERROR, error);
                    reject(error);
                });
        });
    }
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [SET_USERS](state, users) {
        state.users = users;
    },
    [SET_USERS_MANAGE](state, usersManage) {
        state.usersManage = usersManage;
    },
    [SET_USER_HAVE_CLASSE](state, user_have_classe) {
        state.user_have_classe = user_have_classe;
    },
    [SET_USER_CLASSES](state, user_classes) {
        state.user_classes = user_classes;
    },
    [SET_CLASSEUSER](state, classeUser) {
        state.classeUser = classeUser;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
