import ApiService from '@/core/services/api.service';

export const LIST_ENTRY_ERROR = 'listEntryError';
export const SET_ENTRY_ERROR = 'setEntryError';
export const SET_ERROR = 'setError';

const state = {
    errors: null,
    entryError: []
};

const getters = {
    getEntryError(state) {
        return state.entryError;
    }
};

const actions = {
    [LIST_ENTRY_ERROR](context, data) {
        return new Promise((resolve) => {
            ApiService.query(`academy/clientclasses/${data.classCustomerId}/assign-error`, data).then((result) => {
                context.commit(SET_ENTRY_ERROR, result.data);
                resolve(result);
            });
        });
    }
};

const mutations = {
    [SET_ENTRY_ERROR](state, entry_errors) {
        state.entryError = entry_errors;
    },
    [SET_ERROR](state, error) {
        state.errors = error;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
