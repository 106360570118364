import store from '@/core/services/store/index';

export default function authenticated(roles = []) {
    return function ({ next }) {
        const currentUser = store.getters.currentUser;
        if (!currentUser || !roles.includes(currentUser.role)) {
            return window.location.replace(process.env.VUE_APP_PLATEFORME_URL);
        }
        return next();
    };
}
