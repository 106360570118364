import ApiService from '@/core/services/api.service';

export const LIST_LESSON_ITEMS = 'listLessonItems';
export const SET_LESSON_ITEMS = 'setLessonItems';
export const SET_ERROR = 'setError';

const state = {
    errors: null,
    lessonItems: {}
};

const getters = {
    getLessonItems(state) {
        return state.lessonItems;
    }
};

const actions = {
    [LIST_LESSON_ITEMS](context, data) {
        return new Promise((resolve) => {
            ApiService.get(process.env.VUE_APP_API_URL, 'academy/lessonsItems/' + data).then((result) => {
                context.commit(SET_LESSON_ITEMS, result.data);
                resolve(result);
            });
        });
    }
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [SET_LESSON_ITEMS](state, lessonItems) {
        state.lessonItems = lessonItems;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
